:root {
  --background: #e7e7e7;
  --background-alt: #f5f5f5;
  --background-grey: #3d3d3d;
  --background-black: #2a2929;
  --text: #1e1d1d;
  --text-white: #fafafa;
  --text-muted: #757575;
  --text-hover: #2dc1ff;

  --secondary: #009adc;
  --secondary-shadow: hsla(0, 0%, 17%, 0.38);

  --primary-gradient: linear-gradient(90deg, rgba(104,179,252,1) 0%, rgba(17,174,255,1) 25%, rgba(66,151,252,1) 75%, rgba(56,117,190,1) 100%);
  --primary-gradient-rotate: linear-gradient(120deg, rgba(104,179,252,1) 0%, rgba(17,174,255,1) 25%, rgba(66,151,252,1) 75%, rgba(56,117,190,1) 100%);


  --purple: #9f39ff;
  --purple-dark: #9523ff;
  --purple-light: #a953fa;
  --pink: #ff4de7;
  --pink-dark: #f02cd6;
  --pink-light: #ff69eb;
  --red: #ff3d3d;
  --red-dark: #ff1010;
  --red-light: #ff5252;
  --orange: #ff943d;
  --orange-dark: #ff841f;
  --orange-light: #ffa459;
  --yellow: #fff93d;
  --yellow-dark: #eee714;
  --yellow-light: #fffa74;
  --green: #6dff3d;
  --green-dark: #54ff1b;
  --green-light: #8cff66;
  --green-blue: #3dffbb;
  --green-blue-dark: #17eea3;
  --green-blue-light: #61ffc8;
}

html {
  scroll-behavior: smooth;
}

* {
  text-decoration: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--background);
}

p, a, h1, h2, h3, h4, h5, h6 {
  color: var(--text);
}

#page-container {
  min-height: 100svh;
}

/* General */

.text {
  color: var(--text);
}

.text-white {
  color: var(--text-white);
}

.text-muted {
  color: var(--text-muted);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 3px;
}

.text-header {
  text-transform: uppercase;
  letter-spacing: .8vw;
  font-size: 4vw;
  font-weight: 300;
}

.page-header {
  text-transform: uppercase;
  letter-spacing: .8vw;
  font-size: 6rem;
  font-weight: 800;
}

.pointer {
  cursor: pointer;
}

.no-border {
    border: none !important;
}

.bg {
  color: var(--background);
}

.bg-fill {
  fill: var(--background);
}

.sc-fill {
  fill: var(--secondary);
}

.box-shadow-s {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

.box-shadow-l {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
}

.underline {
  text-decoration: underline;
}

.no-radius  {
  border-radius: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-s {
  margin: 1rem;
}

.margin-m {
  margin: 2rem;
}

.margin-l {
  margin: 4rem;
}

.margin-page {
  margin: 0 10rem;
}

.margin-top-s {
  margin-top: 1rem !important;
}

.padding-page-top {
  padding-top: 65px;
}

.gap-s {
  gap: 1rem;
}

.gap-m {
  gap: 2rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.center-horizontal {
  justify-content: center;
}

.center-vertical {
  align-items: center;
}

.justify-c-center {
  justify-content: center;
}

.justify-c-space-between {
  justify-content: space-between;
}

.justify-c-space-around {
  justify-content: space-around;
}

.justify-c-space-evenly {
  justify-content: space-evenly;
}

.button-primary {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 4px;
  line-height: 60px;
  cursor: pointer;

  border-radius: .5rem;
  background: var(--background-black);
  box-shadow: 1em 1em 0 var(--secondary-shadow);

  height: 60px;
  overflow: hidden;
  padding: 0 15px;
  z-index: 5;

  margin: 25px;
  border: none;

  transition: .5s cubic-bezier(0.5, 1, 0.89, 1);
  will-change: transform;
}

.button-primary:hover {
  background: var(--background-grey);
  transform: translate(.3em, .3em);
  box-shadow: .4em .4em 0 var(--secondary-shadow);
}

.button-primary:active {
  transition: .3s;
  transform: translate(.6em, .6em);
  box-shadow: 0 0 .5em var(--text);
}

.button-primary span {
  position: relative;
  z-index: 7;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  transition: transform .3s cubic-bezier(0.5, 1, 0.89, 1);
}

.button-primary span:nth-child(2) {
  transform: rotate(180deg);
  font-size: 30px;
}

.button-primary:hover span {
  transform: translateY(-100%) rotate(0deg);
}

.button-primary:hover span:nth-child(2) {
  transition: transform .3s;
  transform: translateY(-100%) rotate(0deg);
}

.stroke-dashed {
  stroke-dasharray: 50;
}

.stroke-dashed:hover {
  stroke-dasharray: 546.198242188;
}

.stroke-purple {
  stroke: var(--purple-dark);
}

.stroke-pink {
  stroke: var(--pink-dark);
}

.stroke-red {
  stroke: var(--red-dark);
}

.stroke-orange {
  stroke: var(--orange-dark);
}

.stroke-yellow {
  stroke: var(--yellow-dark);
}

.stroke-green {
  stroke: var(--green-dark);
}

.stroke-aqua{
  stroke: var(--green-blue-dark);
}

.stroke-blue {
  stroke: var(--secondary);
}

.stroke-purple:hover {
  fill: var(--purple-light);
}

.stroke-pink:hover {
  fill: var(--pink-light);
}

.stroke-red:hover {
  fill: var(--red-light);
}

.stroke-orange:hover {
  fill: var(--orange-light);
}

.stroke-yellow:hover {
  fill: var(--yellow-light);
}

.stroke-green:hover {
  fill: var(--green-light);
}

.stroke-aqua:hover {
  fill: var(--green-blue-light);
}

.stroke-blue:hover {
  fill: var(--text-hover);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-grey);
}

/* Navbar */

.navbar {
  background-color: var(--background-black);
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7vh;

  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;

  transition: .4s;
}

.navbar-main {
  max-height: 65px;
  min-height: 65px;
}

.navbar-small {
  max-height: 30px;
  height: 30px;
  justify-content: center;
  padding: 0;
}

.navbar-main li {
  list-style: none;
  display: inline-block;
  padding: 10px 15px;
  will-change: transform;
}

.navbar-link {
  font-weight: bolder;
  font-size: 20px;
  text-decoration: none;
  color: var(--text-white);
  transition: .2s ease-in-out;
  text-transform: uppercase;
}

.navbar-link:hover {
  color: var(--text-hover);
}

.navbar-link:active {
  color: var(--secondary);
}

.navbar-click-top {
  display: flex;
  justify-content: center;
  height: 30px;
  width: 100%;
}

.nav-text-header {
  font-size: 2vw;
  letter-spacing: 0;
  font-weight: 1000;
  cursor: pointer;
  overflow: hidden;
  text-transform: uppercase;
}

.nav-text-header span {
  position: relative;
}

.nav-text-header-letter-out {
  display: inline-flex;
  transition: transform .5s;
  will-change: transform;
}

.nav-text-header:hover .nav-text-header-letter-out {
  transition: transform .5s;
  transform: translateY(-100%);
}

span .nav-text-header-letter-in {
  position: absolute;
  left: 0;

  background: var(--primary-gradient);
  background-clip: text;
  background-size: 500%;
  color: transparent;
  animation: letter-bg-gradient 10s infinite linear;

  transform: translateY(100%);
  transition: transform .5s;
  will-change: transform;
}

.nav-text-header:hover span .nav-text-header-letter-in {
  transition: transform .5s;
  transform: translateY(0);
}

@keyframes letter-bg-gradient {

  0%, 100% {
    background-position: left;
  }

  50% {
    background-position: right;
  }

}

.nav-text-header-space {
  margin: 0 .35vw 0 .35vw;
}

.hamburger-menu {
  position: fixed;
  right: 0;
  width: 10vh;
  min-width: 100vw;
  height: 100vh;
  background-color: var(--background);
  z-index: 90;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hamburger-menu li {
  margin: 5vh 0;
  list-style: none;
}

.hamburger-menu li .navbar-link {
  color: var(--text-white);
}

/* Footer */

footer {
  min-height: 225px;
  width: 100%;
  background-color: var(--background-grey);
  overflow: hidden;
}

footer .capsule-div {
  width: 25vw;
  min-height: 225px;
}

.capsule-div div {
  width: 100%;
}

footer img {
  max-height: 200px;
  aspect-ratio: 1/1;
}

.footer-image-link {
  margin: 2rem 0;
}

.footer-partner-div a img {
  height: 50px;
  width: 200px;
  transition: transform .4s;
  will-change: transform;
}

.footer-partner-div a p {
  transition: transform .4s;
  will-change: transform;
}

.footer-partner-div a:hover img {
  transform: translateY(-5px);
}

.footer-partner-div a:hover p {
  transform: translateY(-5px);
}

footer .fa {
  height: 50px;

  transition: color .5s, transform .4s;
  will-change: color;
}

.fa-link {
  color: #ffc637;
}

.fa-twitter {
  color: #1d9bf0;
}

.fa-whatsapp {
  color: #4FE676;
}

.fa-facebook {
  color: #2e89ff;
}

.fa-telegram {
  color: #1D99D4;
}

.fa:hover {
  color: var(--text-white);
  transform: translateY(-5px);
}

/* Main Page */

.video-div {
  height: 100svh;
  background: #000;
  display: flex;
  overflow: hidden;
}


.video-div video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-div img {
  max-height: 25svw;
  aspect-ratio: 1/1;
  margin: 0 7vw;
  z-index: 3;
}

.mouse-scroll {
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3vh;
  display: block;
}

.arrow {
  display: block;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: var(--background-alt);
  margin: 0 0 3px 5px;
}

.mouse-down-arrow-1 {
  margin-top: 6px;
}

.mouse-icon {
  height: 21px;
  width: 14px;
  border-radius: 10px;
  transform: none;
  border: 2px solid var(--background-alt);
  top: 170px;
}

.mouse-wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: var(--text-white);
  border-radius: 1rem;
  position: relative;
}

/* Main Page -> Kdo Jsme */

.kdo-jsme-inside {
  background-color: var(--background);
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
  grid-template-rows: 1fr;
}

.kdo-jsme-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
  padding: 3vw 0 3vw 0;
}

.kdo-jsme-content p {
  margin: 10vw 0 0 .5vw;
  font-size: 27px;
  text-align: left;
}

.kdo-jsme-deco-right  {
  display: flex;
  justify-content: end;
  align-items: end;
  padding-bottom: 7vw;
}

.kdo-jsme-deco-left {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding-top: 5vw;
}

.kdo-jsme-blob {
  width: 20vw;
  height: 20vw;
  z-index: 2;
}

.kdo-jsme-blob path {
  stroke-width: 35;
  fill: var(--background);
  transition: ease-out .4s;
}

.kdo-jsme-blob path:hover {
  stroke-width: 70;
}

/* Main Page -> Akce Section */

.akce-section-main {
  background-color: var(--secondary);
}

.akce-section-main:before {
  content: '';
  display: block;
  width: 100%;
  height: 20vw;

  background-size: 100%;
  background-color: var(--secondary);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='rgb(231 231 231)' viewBox='0 0 1185 248'><circle cx='76' cy='121.1' r='20' class='a'/><circle cx='870' cy='201.1' r='11' class='a'/><circle cx='814.5' cy='165.6' r='24.5' class='a'/><path d='M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z' class='bg-fill'/></svg>");

  z-index: 0;
}

.akce-section-main:after {
  content: '';
  display: block;
  width: 100%;
  height: 20vw;

  background-size: 100%;
  background-color: var(--background);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='rgb(0 154 220)' viewBox='0 0 1920 1080'><path d='M0 310L45.7 295C91.3 280 182.7 250 274.2 234.8C365.7 219.7 457.3 219.3 548.8 210C640.3 200.7 731.7 182.3 823 170.2C914.3 158 1005.7 152 1097 163.7C1188.3 175.3 1279.7 204.7 1371.2 209.5C1462.7 214.3 1554.3 194.7 1645.8 178.5C1737.3 162.3 1828.7 149.7 1874.3 143.3L1920 137L1920 0L1874.3 0C1828.7 0 1737.3 0 1645.8 0C1554.3 0 1462.7 0 1371.2 0C1279.7 0 1188.3 0 1097 0C1005.7 0 914.3 0 823 0C731.7 0 640.3 0 548.8 0C457.3 0 365.7 0 274.2 0C182.7 0 91.3 0 45.7 0L0 0Z' class='bg-fill' /></svg>");

  z-index: 0;
}

.akce-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .button-primary {
    margin-top: 10px;
    margin-bottom: 50px;
  }

}

.scroll-text-main {
  height: 100%;
  width: 11px;
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  rotate: 90deg;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

.marquee2 span {
  animation-delay: 25s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.wave-separator {

  position: absolute;
  z-index: 0;
}

.wave-separator-bottom {
  margin-top: -1vw;
}

/* Main Page -> Stroje Section */

.stroje-index-main {
  background-color: var(--background);
}

.stroje-index-main .text-header {
  margin-bottom: 40px;
}

.stroje-index-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.stroje-index-trains div {
  margin: 3vh 0;
  align-items: flex-end;
}

.stroje-index-trains div:nth-child(1) {
  transform: translateX(-25vw);
}

.stroje-index-trains div:nth-child(2) {
  transform: translateX(-15vw);
}

.stroje-index-trains div:nth-child(3) {
  transform: translateX(-5vw);
}

.stroje-index-trains img {
  width: 100%;
  margin-bottom: 10px;
}

.stroje-index-trains p {
  width: 300px;
  transform: translateX(5vw);
}

.stroje-index-text {
  margin: 5rem;
}

.stroje-index-text p {
  font-size: 25px;
}

.stroje-index-lokomotiva-mobil {
  margin: 25px 0;
  display: none;
}

.stroje-index-lokomotiva-mobil img {
  width: 100%;
  margin-bottom: 10px;
}

/* Main Page -> Jízda Section */

.jizda-section-main {
  padding: 6rem 0;
  background: var(--background);
  background: var(--primary-gradient-rotate);
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.16);
  margin-top: 2rem;
}

.jizda-section-divider {
  margin: 4rem 0;
}

.jizda-section-divider span {
  width: 40vw;
  height: 3px;
  background: #fff;
  border-radius: 1rem;
}

.jizda-section-divider p {
  margin: 0 20px 0 24px;
  font-size: 40px;
  font-weight: 300;
  color: #fff;
}

.jizda-section-p {
  font-size: 25px;
  margin: 0 15px;
}

.jizda-section-h1 {
  margin-bottom: 20px;
}

/* Blog */

.blog-preview-capsule {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-preview-main {
  overflow: hidden;
  margin: 2rem;
  height: 350px;
  min-width: 765px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-alt);
  border-radius: 1.5rem;
  z-index: 2;
}

.blog-preview-deco {
  height: 350px;
  width: 765px;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='35' ry='35' stroke='rgb(42,41,41)' stroke-width='10' stroke-dasharray='22' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 35px;

  position: absolute;
  z-index: 1;
}

.blog-preview-deco-left{
  transform: translate(-2rem, -2rem);
}

.blog-preview-deco-right {
  transform: translate(2rem, -2rem);
}

.blog-preview-inside {
  display: grid;
  z-index: 3;
}

.blog-preview-inside div {
  transition: .3s;
}

.blog-preview-inside img {
  height: 268px;
  width: 676px;
  mask-size: contain;
  mask-repeat: no-repeat;
  filter: brightness(.8) hue-rotate(0deg);
  transition: .3s;
  object-fit: cover;
}

.blog-preview-left {
  transform: translate(-4px, 0);
  width: 676px;
}

.blog-preview-right {
  position: absolute;
  transform: translate(4px, 0);
}

.blog-preview-inside .text-div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  text-shadow: 0 0 6px var(--text);

  z-index: 2;
}

.blog-preview-left .text-div {
  transform: unset;
  left: 25px;
  top: 0;
  bottom: 0;
}

.blog-preview-left .text-div h1 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 24px;
}

.blog-preview-left .text-div p {
  position: absolute;
  bottom: 22px;
  width: 490px;
  text-align: justify;
  font-size: 18px;
}

.blog-preview-left .header-div {
  position: absolute;
  top: 12px;
}

.blog-preview-left .text-div .header-div p {
  bottom: unset;
  position: unset;
  font-weight: bolder;
}

.blog-preview-right .text-div {
  transform: translateY(-50%) rotate(270deg);
  right: -46px;

  display: inline-flex;
  align-items: center;

  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 7px;
  text-transform: uppercase;
}

.blog-preview-right .text-div .arrow {
  transform: rotate(-45deg);
  margin: 0;
  width: 13px;
  height: 13px;
  border-width: 0 4px 4px 0;
  transition: .3s;
}

.blog-preview-right .blog-preview-link {
  padding: 51px 0;
}

.blog-preview-img-left {
  mask-image: url("../public/img/svg/ticket/ticket-rest2.svg");
}

.blog-preview-img-right {
  mask-image: url("../public/img/svg/ticket/ticket-top2.svg");
  mask-position: right;
}

.blog-preview-inside:hover img {
  filter: brightness(.5);
}

.blog-preview-inside:hover .blog-preview-right {
  transform: translate(13px, 0);
}

.blog-preview-inside:hover .blog-preview-right .text-div .arrow {
  transform: rotate(315deg);
}

.blog-preview-inside:hover .blog-preview-left {
  transform: translate(-13px, 0);
}

/* Blog -> Mobile */

.blog-mobile-main {
  width: 400px;
  min-height: 600px;
  border-radius: 25px;
  margin: 3vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.blog-mobile-img-div {
  height: 160px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.blog-mobile-img-div img {
  width: 400px;
}

.blog-mobile-content {
  min-height: 425px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 25px;

  background-color: var(--background-alt);
}

.blog-mobile-content h1 {
  font-size: 30px;
}

.blog-mobile-content p {
  font-size: 20px;
  margin: 25px 0;
  text-align: justify;
  word-break: break-word;
}

.blog-mobile-button-div {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 10px;
  padding: 15px;
  border-radius: 0.5rem;

  background-color: var(--background-black);
}

.blog-mobile-button-div p {
  margin: 0 7px 0 0;
}

.blog-mobile-button-div .arrow {
  rotate: -90deg;
  width: 14px;
  height: 14px;
  margin: unset;
  border-width: 0 3px 3px 0;
}

.blog-mobile-divider {
  background-color: var(--secondary);
  height: 15px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.blog-mobile-cutout {
  height: 40px;
  width: 40px;
  border-radius: 40px;

  z-index: 5;

  background-color: var(--secondary);
}

.blog-mobile-divider:before {
  content: '';
  height: 40px;
  width: 40px;
  border-radius: 40px;

  z-index: 5;
  position: absolute;
  transform: translateX(-210px);

  background-color: var(--secondary);
}

.blog-mobile-divider:after {
  content: '';
  height: 40px;
  width: 40px;
  border-radius: 40px;

  z-index: 5;
  position: absolute;
  transform: translateX(210px);

  background-color: var(--secondary);
}

.blog-mobile-divider.blog-mobile-divider-normal-bg:before {
  background-color: var(--background);
}

.blog-mobile-divider.blog-mobile-divider-normal-bg:after {
  background-color: var(--background);
}

.blog-mobile-divider-normal-bg {
  background-color: var(--background);
}

/* Blog -> Page */

.akce-main {
  padding-top: 5rem;
}

/* Blog -> Read */

.blog-read-header h1 {
  color: var(--text-white);
  text-shadow: 0 0 6px var(--text);
  position: absolute;
  top: 240px;
  font-size: 75px;
}

.blog-read-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  background-color: rgba(126, 126, 126, 0.53);
}

.blog-read-top {
  width: 100%;
  justify-content: space-between;
}

.blog-read-subtitle {
  width: 70%;
}

.blog-read-content {
  margin: 5rem 10rem;
}

.blog-read-content p {
  font-size: 1.3vw;
  margin: 1rem 0;
}

.blog-read-content img {
  max-width: 85%;
  max-height: 90svh;
  border-radius: 1rem;
  margin: 2rem 0 1rem;
}

.blog-read-poster {
  max-width: 80vw;
  max-height: 100vh;
}

.blog-read-back {
  position: absolute;
  top: 95px;
  left: 30px;
}

.blog-read-back p {
  text-shadow: 2px 4px 5px var(--text);
  font-weight: 1000;
  margin: 5px;
  font-size: 17px;
}

/* Kontakt */

.kontakt-bg-div {
  height: 100svh;
  background-image: url("../public/img/kontakt.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.kontakt-main {
  width: 80vw;
  min-height: 65svh;
  border-radius: 22px;

  background-color: rgba(0, 0, 0, 0.45);
  overflow: hidden;

  padding: 2rem;
  backdrop-filter: blur(15px);
  transform: translateY(30px);
}

.kontakt-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: 1rem;
}

.kontakt-map {
  border: none;
  border-radius: 16px;
  background-color: var(--background);
}

.kontakt-tile-white {
  background-color: var(--background-alt);
  border-radius: 9px;
  height: 125px;
  width: 300px;
}

.kontakt-tile-whiter {
  background-color: #fff;
  border-radius: 9px;
  height: 125px;
  width: 300px;
}

.kontakt-margin {
  margin-top: 10px;
}

/* O Nás */

.onas-main {
  overscroll-behavior: none;
}

.onas-header {
  padding-top: 5rem;
}

.onas-popis-text {
  padding: 3rem 7rem;
}

.onas-popis-text p {
  font-size: 20px;
}

.onas-popis-text ul {
  margin: 5px 0;
  padding: revert;
}

.onas-stats {
  max-width: 100%;
  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #ff975c 0%, #fdc740 100%);
  padding: 4rem 0 2rem;
  margin: 0 4rem;
  border-radius: 3rem;
  overflow: hidden;
}

.onas-stats-content-div {
  width: 100%;
}

.onas-stats-content-div h2 {
  font-size: 35px;
  z-index: 5;
}

.onas-stats-content-div h1:nth-child(1) {
  font-size: 55px;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.onas-stats-number-div h1:nth-child(2) {
  color: var(--orange-dark);

  position: absolute;
  font-size: 1000%;
  z-index: 2;

  transform: translateY(-15px);
}

.onas-stats-separator {
  z-index: 5;
}

.onas-stats-separator h1 {
  font-size: 155px;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
}

.onas-stroje {
  width: 100%;
  padding: 3rem 0;
}

.onas-stroje-showcase {
  width: 100%;
}

.onas-stroje-showcase h2 {
  font-size: 40px;
  margin-left: 4rem;
  margin-top: 2rem;
}

.onas-stroje-showcase-div {
  width: 100%;
  padding-top: 1.5rem;
}

.onas-stroje-showcase-div:hover .onas-carousel-buttons span {
  opacity: 1;
  width: 50px;
  height: 50px;
}

.onas-stroje-showcase-div:hover .onas-carousel-buttons span svg {
  font-size: 25px;
}

.onas-carousel {
  width: 100%;
  height: 250px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  padding-right: 6rem;
}

.onas-carousel::-webkit-scrollbar {
  display: none;
}

.onas-carousel-gondola {
  width: 420px;
  min-width: 420px;

  padding: 1.5rem;

  scroll-snap-align: none center;
  transform: translateX(6rem);
  transition: .4s;
}


.onas-carousel-gondola p {
  font-weight: 800;
  text-shadow: var(--text) 2px 2px 2px;

  transition: .3s;
}

.onas-carousel-gondola:hover {
  transform: scale(110%) translateX(5.4545rem);
}

.onas-carousel-gondola:hover p {
  position: absolute;
  font-size: 20px;
}

.onas-carousel-gondola-image {
  height: 100%;
  width: 100%;

  align-items: flex-end;
  justify-content: flex-start;
  padding: .8rem 1.2rem;

  border: .5rem var(--background-alt) solid;
  border-radius: 15px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.onas-carousel-buttons {
  width: 100%;
  height: 0;
  position: absolute;
  padding: 0 4rem;
}

.onas-carousel-buttons span {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: rgba(30, 30, 30, 0.75);

  transform: translateY(125px);
  transition: .8s;
  opacity: 0;
}

.onas-stroje-showcase .onas-carousel-buttons span:hover {
  background: rgba(20, 20, 20, 0.85);
  width: 53px;
  height: 53px;
}

.onas-stroje-showcase .onas-carousel-buttons span:active {
  background: rgba(25, 25, 25, .95);
}

.onas-carousel-buttons span svg {
  color: var(--background);
  font-size: 12px;
  transition: .8s;
}

.modal-backdrop {
  width: 100vw;
  height: 100vh;

  position: absolute;
  z-index: 8;

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.modal-shell {
  background: var(--background);
  width: 950px;

  border-radius: 1rem;
  overflow: hidden;
  margin: 1rem;
}

.modal-top {
  margin: 15px;
  align-items: flex-start;
}

.modal-top svg {
  color: var(--secondary-shadow);
  font-size: 25px;
  transition: .3s;
}

.modal-top svg:hover {
  color: rgba(43, 43, 43, 0.69);
}

.modal-bottom {
  max-height: 450px;
  justify-content: flex-end;
}

.modal-text p {
  padding: 10px 25px 15px;
  text-align: justify;
}

.modal-image {
  width: 100%;
  height: 375px;
  background-position: center;
  background-size: cover;
}

/* Rezervační systém */

.ticket-selector {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
}

.ticket-selector-max {
  color: #666;
  font-size: 0.9em;
}

.ticket-type {
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.ticket-info {
  flex: 1;
}

.ticket-controls {
  gap: 10px;
}

.ticket-btn {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.85);
  background: #f5f5f5;
  cursor: pointer;
}

.ticket-count {
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}

.ticket-summary {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #eee;
}

.reservation-summary {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
}

.summary-section {
  margin-bottom: 15px;
}

.summary-item {
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.summary-info {
  flex: 1;
}

.summary-total {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #eee;
}

.summary-seats {
  margin-bottom: 10px;
  .summary-item:last-child {
    border-bottom: none !important;
  }
}

.ticket-count {
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}


/* Login */

.login-page {
  height: 100svh;
}

.login-container {
  width: 400px;
  background: var(--background-alt);
  border-radius: .5rem;
  padding: 1rem;
}

.login-container h1 {
  font-size: xx-large;
  font-weight: 300;
  padding: 20px 0;
}

.login-container .flex-column {
  margin: 1.5rem 0;
}

.login-container input {
  border-radius: 6px;
  height: 25px;
  width: 250px;
  font-weight: 600;
}

.login-button {
  width: 150px;
  height: 50px;

  margin-bottom: 20px;
  cursor: pointer;

  border: none;
  border-radius: 5px;

  background-color: var(--text-hover);
  font-weight: 900;
  transition: .3s;
}

.login-button:hover {
  transform: scale(105%);
}

.login-button:active {
  background: var(--secondary);
  transform: scale(100%);
}

.login-button-verify {
  width: 90%;
  animation: button-click 2s ease infinite;
  background: linear-gradient(90deg, var(--text-hover), var(--secondary));
  transition: 0.2s;
  background-size: 400%;
  color: var(--text-white);
  border-radius: 6px;
}

.login-text-error {
  color: var(--red-dark);
}

.login-text-success {
  color: var(--green-blue-dark);
}

@keyframes button-click {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* Admin */

.admin-dash {
  min-height: 100svh;
  margin-top: 65px;
}

.admin-dash-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.admin-dash-column {
  width: 400px;
}

.admin-dash-tile {
  background: var(--background-alt);
  padding: 1.5rem;
  border-radius: 10px;
  max-width: 100%;
  margin: .5rem 0;
}

.admin-dash-tile h2 {
  padding-bottom: 10px;
}

.admin-dash-tile svg {
  cursor: pointer;
}

.admin-dash-tile-warning {
  background: var(--orange-light) !important;
}


.admin-dash-tile-stats {
  width: 100%;
}

.admin-dash-user-manager div {
  margin: 5px;
}

.admin-dash-user-manager svg {
  color: rgba(255, 57, 80, 0.8);
}

.admin-dash-button {
  background-color: var(--background-black);
  color: var(--text-white);
  font-weight: 600;
  padding: .8rem;
  margin: 0 10px;
  border-radius: 5px;
  transition: .2s;
  cursor: pointer;
}

.admin-dash-button:hover {
  background-color: var(--background-grey);
  transform: translateY(-3px);
}

.admin-dash-button:active {
  background-color: var(--text-muted);
}

.admin-dash-icon-margin {
  margin-right: 15px;
}

.admin-back {
  top: 95px;
  left: 2rem;
  max-width: 110px;
  cursor: pointer;
  position: absolute;
}

.admin-back p {
  font-weight: 1000;
  margin: 5px;
  font-size: 17px;
}

.admin-page {
  margin: 0 2rem;
  padding: 145px 0 2rem;
}

/* Admin -> Akce */

.admin-akce-icony {
  margin-left: 1.5rem;
}

.admin-akce-icony svg {
  font-size: 32px;
  margin: .3rem 0;
  color: var(--text);
  transition: .4s;
  cursor: pointer;
}

.admin-akce-icony .fa-square-pen:hover {
  color: var(--secondary);
}

.admin-akce-icony .fa-square-minus:hover {
  color: var(--red-dark);
}

/* Admin -> Akce -> New */

.admin-akce-new {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;

  .admin-dash-tile {
    gap: .5rem;
  }

}

.aan-fields {
  grid-row: 1 / 3;
}

.aan-preview-widget {

}

.aan-preview-full {

}

/* Admin -> Stroje ->New */

.asn-preview-widget div {
  transform: none;
  height: 250px;
  width: 420px;}

.asn-preview-widget div:hover {
  transform: none;
}

/* Admin -> Rezervace -> Upravit */

.aru {

  .admin-dash-tile {
    gap: .5rem;
    width: 35%;
  }
}

.spesl {
  height: 50px;
  width: 50px;
  color: var(--background-grey);
}

/* Fucking Media */

@media (max-width: 1350px) {

  .kontakt-bg-div {
    height: unset;
  }

  .kontakt-main {
    height: unset;
    margin: 100px 0;
  }

  .kontakt-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .kontakt-grid .flex-row {
    grid-column: 1 / 3;
    margin: 25px 0;
  }

}

@media (max-width: 1200px) {

  footer .fa {
    height: 30px;
  }

  .footer-partner-div a img {
    height: 30px;
  }

}

@media (max-width: 1050px) {

  .navbar-link {
    font-size: 5vw;
    color: var(--text);
  }

  .nav-text-header {
    font-size: 20px;
  }

  .blog-read-content {
    margin: 5rem 5rem;
  }

  .blog-read-content p {
    font-size: 17px;
  }

  footer .footer-partner-div a p {
    font-size: 15px;
    letter-spacing: 2px;
  }

  .kontakt-main p {
    font-size: 13px;
  }

  .onas-stats-content-div h2 {
    font-size: 20px;
  }

  .onas-stats-content-div h1:nth-child(1) {
    font-size: 30px;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  }

  .onas-stats-number-div h1:nth-child(2) {
    font-size: 700%;
  }

  .onas-stats-separator h1 {
    font-size: 90px;
  }

}

@media (max-width: 900px) {

  .blog-preview-main {
    margin: 1.5rem;
    height: 300px;
    min-width: 656px;
  }

  .blog-preview-deco {
    height: 300px;
    width: 656px;
  }

  .blog-preview-inside img {
    height: 226px;
    width: 569px;
  }

  .blog-preview-left {
    width: 569px;
  }

  .blog-preview-left .text-div {
    left: 20px;
  }

  .blog-preview-left .text-div h1 {
    font-size: 22px;
  }

  .blog-preview-left .text-div p {
    width: 410px;
    font-size: 16px;
  }

  .blog-preview-right .text-div {
    font-size: 18px;
    letter-spacing: 6px;
    right: -40px;
  }

  .blog-preview-right .text-div .arrow {
    border-width: 0 3px 3px 0;
  }

  .blog-preview-right .blog-preview-link {
    padding: 45px 0;
  }

  .kontakt-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .kontakt-grid .flex-row {
    grid-column: 1;
  }

  .stroje-index-trains img {
    width: 200%;
  }

  .stroje-index-trains p {
    transform: translateX(10vw);
  }

  .jizda-section-divider span {
    width: 25vw;
  }

  .jizda-section-p {
    font-size: 17px;
  }

  .admin-dash {
    margin-top: 60px;
  }

  .admin-dash-grid {
    grid-template-columns: 1fr;
  }

}

@media (max-width: 825px) {
  .navbar-main {
    padding: 0 10px;
  }
}

@media (max-width: 750px) {
  .navbar-link {
    font-size: 7vw;
  }

  .nav-text-header-space {
    margin: 0 .45vw 0 .45vw;
  }

  footer {
    flex-direction: column-reverse;
  }

  footer .capsule-div {
    width: 70vw;
  }

  footer .fa {
    height: 50px;
  }

  .footer-partner-div a img {
    height: 50px;
  }

  .footer-image-link {
    margin: 5vh 0;
  }

  .video-div {
    justify-content: center;
  }

  .video-div img {
    max-height: 60svw;
    margin: 0;
  }

  .stroje-index-text {
    margin: 1rem;
  }

  .stroje-index-text p {
    font-size: larger;
  }

  .stroje-index-trains p {
    width: 150px;
    transform: translateX(5vw);
  }

  .page-header {
    font-size: 5rem;
  }

  .onas-popis-text {
    padding: 3rem 3rem;
  }

  .onas-stats {
    margin: 0 2rem;
    border-radius: 2rem;
  }

}

@media (max-width: 700px) {

  .kdo-jsme-inside {
    grid-template-columns: none;
    padding: 0 10vw;
  }

  .kdo-jsme-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .kdo-jsme-content h1 {
    margin: 60px 0 5vw 0;
  }

  .text-header {
    font-size: xxx-large;
  }

  .kdo-jsme-content p {
    font-size: larger;
    margin: unset;
  }

  .kdo-jsme-deco-left {
    display: none;
  }

  .kdo-jsme-deco-right {
    display: none;
  }

  .blog-read-content {
    margin: 5rem 2rem;
  }

  .onas-stats {
    margin: 0 1.5rem;
  }

}

@media (max-width: 575px) {

  .blog-read-content .blog-read-top {
    flex-direction: column-reverse;
    align-items: center;
  }

  .kontakt-main {
    width: 95%;
  }

  .kontakt-grid {
    grid-template-rows: 300px 1fr 1fr;
  }

  .kontakt-map {
    height: 250px;
    width: 250px;
  }

  .kontakt-tile-white, .kontakt-tile-whiter {
    margin: 10px 0;
  }

  .stroje-index-text .button-primary {
    font-size: 9.8px;
  }

  .onas-stats-separator {
    padding: 2rem 0 4rem;
  }

  .onas-stats {
    flex-direction: column;
    margin: 0 5.5rem;
  }

  .onas-stroje-showcase h2 {
    text-align: center;
    margin-left: 0;
  }

  .onas-carousel-buttons {
    display: none;
  }

  .modal-text {
    overflow-y: scroll;
    max-height: 285px;
  }

  .modal-text::-webkit-scrollbar-track {
    background-color: var(--background);
  }

}

@media (max-width: 450px) {

  .blog-mobile-main {
    width: 300px;
  }

  .blog-mobile-img-div img {
    width: 300px;
  }

  .blog-mobile-content {
    min-height: 430px;
  }

  .blog-mobile-divider {
    height: 10px;
  }

  .blog-mobile-cutout {
    height: 30px;
    width: 30px;
  }

  .blog-mobile-divider:before {
    height: 30px;
    width: 30px;

    transform: translateX(-155px);
  }

  .blog-mobile-divider:after {
    height: 30px;
    width: 30px;

    transform: translateX(155px);
  }

  footer .fa {
    height: 30px;
  }

  .footer-partner-div a img {
    height: 30px;
  }

  .blog-read-content .button-primary {
    font-size: 10px;
  }

  .kontakt-main {
    padding: 32px 10px 10px;
    border-radius: 13px;
  }

  .kontakt-grid {
    padding-top: unset;
  }

  .kontakt-tile-white, .kontakt-tile-whiter {
    width: 200px;
  }

  .kontakt-tile-white p, .kontakt-tile-whiter p {
    font-size: 11px;
  }

  .stroje-index-content {
    grid-template-columns: 1fr;
  }

  .stroje-index-trains {
    display: none;
  }

  .stroje-index-text {
    margin: 0 10vw;
  }

  .stroje-index-text .button-primary {
    font-size: 16px;
  }

  .stroje-index-lokomotiva-mobil {
    display: initial;
  }

  .jizda-section-h1 {
    font-size: xx-large;
    font-weight: 400;
  }
  .jizda-section-divider p {
    font-size: 30px;
  }

  .admin-dash-column {
    max-width: 250px;
  }

  .admin-dash-tile div {
    flex-direction: column;
  }

  .admin-dash-tile div a {
    margin: 4px 0;
  }

  .admin-dash-tile div div {
    margin: 5px 0;
  }

  .admin-dash-tile-user p {
    margin-bottom: 8px;
    margin-right: unset;
  }

  .page-header {
    font-size: 4rem;
  }

  .kdo-jsme-content .button-primary {
    font-size: 10px;
  }

  .onas-stats {
    margin: 0 3rem;
  }

  .onas-carousel-gondola:hover {
    transform: translateX(6rem);
  }

  .onas-carousel-gondola {
    width: 390px;
    min-width: 390px;
  }

}

@media (max-width: 350px) {

  .kdo-jsme-inside {
    grid-template-columns: none;
    padding: 0 5vw;
  }

  .kdo-jsme-content h1 {
    text-align: center;
    margin: 70px 0 5vw 0;
  }

  .akce-section-content h1 {
    font-size: xx-large;
  }

  .blog-mobile-main {
    width: 90vw;
    margin: 35px 0;
  }

  .blog-mobile-content {
    margin-top: 10px;
    border-radius: 25px;
  }

  .blog-mobile-img-div {
    border-radius: 25px;
  }

  .blog-mobile-img-div img {
    width: 90vw;
    min-width: 250px;
  }

  .blog-mobile-divider {
    display: none;
  }

  .video-div img {
    max-height: 70svw;
  }

  .blog-read-back {
    position: absolute;
    top: 80px;
    left: 15px;
  }

  .stroje-index-text {
    margin: 0 5vw;
  }

  .jizda-section-divider span {
    width: 15vw;
  }

  .page-header {
    font-weight: 600;
    font-size: 3rem;
  }

  .onas-carousel-gondola {
    width: 350px;
    min-width: 350px;
  }

  .onas-stats {
    margin: 0 1rem;
  }

  .onas-popis-text {
    padding: 3rem 1rem;
  }

}

@media (max-width: 310px) {

  .nav-text-header {
    font-size: 6vw;
  }

  .nav-text-header-space {
    margin: 0 .5vw 0 .5vw;
  }

  .blog-read-content .button-primary {
    font-size: 7px;
  }

  .kontakt-map {
    width: 200px;
    height: 200px;
  }

  .admin-dash-column {
    max-width: 200px;
  }

  .admin-dash-tile h2 {
    font-size: 1.2em;
  }

  .admin-dash-tile-user p {
    font-size: .75em;
  }

  .kdo-jsme-content .button-primary {
    font-size: 8px;
  }

  .onas-carousel-gondola {
    width: 250px;
    min-width: 250px;
  }

  .onas-stats {
    margin: 0 .3rem;
  }

}

